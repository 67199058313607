<template>
  <div class="direct-hiring">
    <div
      class="page-header d-flex align-items-center justify-content-center"
      style="background: url(/img/directhiring/header-direct-hiring.jpg); background-size: cover"
    >
      <div class="viewport">
        <h1>A comprehensive and consultative approach</h1>
        <p>
          Carefully aligning candidate experience, skills and personal qualities to match your
          needs.
        </p>
      </div>
      <div class="overlay"></div>
    </div>
    <div class="viewport">
      <div class="hcrowd">
        <h3>Highly targeted direct hiring</h3>
        <div class="d-flex justify-content-between align-items-center">
          <div class="content">
            <h4>A crowded market with high demands</h4>
            <p>
              In today's dynamic and fast-moving landscape, finding, attracting and recruiting the
              most suitable IT professionals can be a true challenge. We take these pain points away
              from our customers by personally engaging with our diverse network of talent to find
              the perfect candidate for your requirements.
            </p>
            <p>
              You hire the candidate directly after we have assisted you throughout the recruiting
              process.
            </p>
          </div>
          <div class="content">
            <img src="/img/directhiring/target-light.jpg" alt="direct hiring" />
          </div>
        </div>
        <h3>We know what to look for</h3>
        <div class="d-flex justify-content-between align-items-center">
          <div class="content">
            <img src="/img/directhiring/obseratory.jpg" alt="knowedge" />
          </div>
          <div class="content">
            <h4>It takes one to know one</h4>
            <p>
              As IT professionals ourselves, we’re able to speak the right language to identify the
              best people for the job. We begin by gaining a deep understanding of your business and
              technical needs, before carrying out an active search to find personnel with the
              specific skills and background you’re looking for.
            </p>
            <p>
              During the last decade we've built a solid network and database with skilled
              candidates, using modern tools and channels (including social media platforms and
              online communities) to find and attract the right competence for you.
            </p>
          </div>
        </div>
        <h3 class="mb-3">Our recruiting process</h3>
        <p class="text-center" style="font-size: 18px">
          We've crafted our recruiting service to align to your way of working, where we agree on a
          tailor-made package of services that ensures that we have good common process for the
          candidates - while ensuring that they are appropriately selected and assessed.
        </p>
      </div>
      <div class="process frst d-flex justify-content-center">
        <div class="box d-flex justify-content-center align-items-center">
          <div class="text">Requirements</div>
        </div>
        <div class="box d-flex justify-content-center align-items-center">
          <div class="text">Sourcing</div>
        </div>
        <div class="box d-flex justify-content-center align-items-center">
          <div class="text">Screening</div>
        </div>
        <div class="box d-flex justify-content-center align-items-center">
          <div class="text">Interviewing</div>
        </div>
        <div class="box d-flex justify-content-center align-items-center">
          <div class="text">Presenting</div>
        </div>
      </div>
      <div class="process car">
        <div
          id="carouselExampleControls"
          class="carousel slide"
          data-bs-ride="carousel"
          data-bs-interval="false"
        >
          <div class="carousel-inner">
            <div class="carousel-item active">
              <div class="d-flex">
                <div class="box d-flex justify-content-center align-items-center">
                  <div class="text">Requirements</div>
                </div>
                <div class="box d-flex justify-content-center align-items-center">
                  <div class="text">Sourcing</div>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div class="d-flex">
                <div class="box d-flex justify-content-center align-items-center">
                  <div class="text">Screening</div>
                </div>
                <div class="box d-flex justify-content-center align-items-center">
                  <div class="text">Interviewing</div>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div class="d-flex">
                <div class="box d-flex justify-content-center align-items-center">
                  <div class="text">Presenting</div>
                </div>
              </div>
            </div>
          </div>
          <button
            class="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleControls"
            data-bs-slide="prev"
          >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button
            class="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleControls"
            data-bs-slide="next"
          >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </div>
      <h3>Our recruiting services</h3>
      <div class="rservices d-flex justify-content-between">
        <div class="s-box">
          <img src="/img/icons/icons8-mailbox-promotion-100.png" alt="icon" />
          Compiling icon for optimum reach and engagement.
        </div>

        <div class="s-box">
          <img src="/img/icons/icons8-head-profile-100.png" alt="icon" />
          Headhunting from internal and external databases.
        </div>

        <div class="s-box">
          <img src="/img/icons/icons8-share-100.png" alt="icon" />
          Social recruiting and advertising on all popular platforms.
        </div>

        <div class="s-box">
          <img src="/img/icons/icons8-filter-100.png" alt="icon" />
          Initial screening and in-depth qualification vetting.
        </div>

        <div class="s-box">
          <img src="/img/icons/icons8-bookmark-100.png" alt="icon" />
          Online programming tests and candidate benchmarking.
        </div>

        <div class="s-box">
          <img src="/img/icons/icons8-connected-people-100.png" alt="icon" />
          Arrangement of customer meetings and interactions.
        </div>

        <div class="s-box">
          <img src="/img/icons/icons8-approval-100.png" alt="icon" />
          Verifying references and other background checks.
        </div>

        <div class="s-box">
          <img src="/img/icons/icons8-passport-100.png" alt="icon" />
          Obtaining and renewing working permits.
        </div>
      </div>

      <div class="padding">
        <h3 class="mt-3">Seven tips on how to find and recruit the right IT professional</h3>
        <p>
          Hiring skilled IT professionals is no easy task. But it’s crucial for your company’s
          growth. So, what’s the secret sauce of successful IT recruiting?
        </p>
        <p>
          If you feel like no one is paying attention to your recruitment ads, or candidates drop
          out during the recruitment process, it might be time to take another look at how you find,
          reach and engage talent.
        </p>
        <p>
          Competition for business-savvy IT talent has never been higher, creating unprecedented
          challenges for those looking to fill technology positions. In today’s world, putting up an
          ad and waiting for staff to come to you is no longer enough. To help you with your next
          recruitment drive, review this whitepaper that discusses seven things to keep in mind when
          looking to build a winning tech team.
        </p>
        <div class="text-center">
          <a
            href="https://comstream.eu/whitepapers/How_to_recruit_top_IT_professionals.pdf"
            class="btn btn-primary"
            download
            >Download Whitepaper</a
          >
        </div>
      </div>
      <div class="call-to-action-boxes d-flex justify-content-between" style="padding-top: 30px">
        <div
          class="box"
          style="background: url('/img/aboutus/success-bg.jpg'); background-size: cover"
        >
          <div class="content">
            <h3 class="mb-2">See what we can do</h3>
            <p>
              An alternative to recruiting is to use our nearshoring services. Find out more about
              how we can take your operations to the next level.
            </p>
            <router-link to="/services/dedicated-nearshore-team" class="btn btn-primary"
              >Read more</router-link
            >
          </div>
          <div class="overlay"></div>
        </div>

        <div
          class="box"
          style="background: url('/img/directhiring/airplane-wing.jpeg'); background-size: cover"
        >
          <div class="content">
            <h3 class="mb-2">Relocation support</h3>
            <p>
              We are certified by the Swedish Migration Agency, and if your candidate needs to
              relocate, we can facilitate all aspects of the process on your behalf.
            </p>
            <router-link to="/relocate-to-sweden" class="btn btn-primary">Read more</router-link>
          </div>
          <div class="overlay"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import titleMixin from '../mixins/titleMixin';
export default {
  mixins: [titleMixin],
  title: 'Tech Recruitment | Comstream',
  mounted() {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
  },
};
</script>
<style lang="scss" scoped>
.direct-hiring {
  h3 {
    font-size: 38px;
    font-weight: 700;
    text-align: center;
    margin: 70px 0 80px 0;
  }
  .hcrowd {
    padding: 20px 0;

    .content {
      width: 48%;
      h4 {
        font-size: 30px;
        font-weight: 700;
        margin-bottom: 30px;
      }
      p {
        font-size: 18px;
      }
      img {
        width: 100%;
        height: auto;
        border-radius: 8px;
      }
    }
  }
  .process {
    .box {
      width: 25%;
      height: 120px;
      background: #f2dcdb;
      text-align: center;
      clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 5% 100%, 30% 51%, 5% 0);
      .text {
        margin-left: 40px;
      }
      &:nth-of-type(2) {
        margin-left: -65px;
        background: #e6b9b8;
      }
      &:nth-of-type(3) {
        margin-left: -65px;
        background: #d99694;
      }
      &:nth-of-type(4) {
        margin-left: -65px;
        background: #953735;
        color: #fff;
      }
      &:nth-of-type(5) {
        margin-left: -65px;
        background: #632523;
        color: #fff;
      }
    }
    &.car {
      display: none !important;
      .carousel {
        .carousel-item {
          .box {
            width: 70%;
            margin-right: 22px;
            .text {
              margin-left: 35px;
            }
          }
          &:first-of-type {
            .box {
              &:first-of-type {
                background: #f2dcdb;
                color: #000;
              }
              &:nth-of-type(2) {
                background: #e6b9b8;
              }
            }
          }
          &:nth-of-type(2) {
            .box {
              &:first-of-type {
                background: #d99694;
                color: #000;
              }
              &:nth-of-type(2) {
                background: #953735;
                color: #fff;
              }
            }
          }
          &:nth-of-type(3) {
            .box {
              width: 100%;
              background: #632523;
              color: #fff;
            }
          }
        }
      }
    }
  }
  .rservices {
    flex-wrap: wrap;
    .s-box {
      margin-bottom: 30px;
      img {
        display: block;
        width: 100px;
        height: 100px;
        margin: 12px auto;
      }
      width: 24.5%;
      text-align: center;
      font-size: 18px;
    }
  }
  .padding {
    padding: 60px 180px;
  }
}

@media only screen and (max-width: 576px) {
  .direct-hiring {
    h3 {
      font-size: 2rem;
      margin: 20px auto 50px auto;
    }
    .hcrowd {
      .d-flex {
        display: block !important;
        .content {
          width: 100%;
        }
      }
    }
    .process {
      &.frst {
        display: none !important;
      }

      &.car {
        display: block !important;
        .carousel {
          .carousel-item {
            .box {
              width: 70%;
              margin-right: 22px;
              .text {
                margin-left: 35px;
              }
            }
          }
        }
      }
    }
    .carousel {
      display: block;
    }
    .rservices {
      display: block !important;
      .s-box {
        width: 75%;
        margin: 12px auto;
      }
    }
    .padding {
      padding: 0;
      margin-top: 20px;
      h3 {
        margin-bottom: 12px;
      }
    }
  }
}
</style>
